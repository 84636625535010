<!-- Liquidacion APDS -->

<template>
  <div class="apds_liquidacion_F">   
      <div class="centrado">
        <v-sheet :elevation="4">     

           <!-- Cabecera   -->
          <div class="cabecera" v-if="Entorno.header.header">       
            <base_Header 
              :Entorno="Entorno.header"
              @onEvent="event_Header">          
            </base_Header>
          </div>

          <!-- Contenido -->
          <!-- <div class="conflex" >  -->
            <div class="columna" style="width:950px">

              <!-- Grid -->
              <base_Fgrid
                v-model="selected"
                :padre="stName" 
                :Entorno="Entorno"  
                height="480px"        
                :items-per-page=-1 
                show-select 
                single-select
                hide-default-footer 
                fixed-header 
                dense
                @onEvent="event_Grid">

                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ }">         
                  <div style="padding:10px 0 0 10px;">
                    <div style="display:flex;justify-content:space-between;width:650px;padding-bottom:15px">

                      <apds_liquidacion_ultimas
                        :padre="padre"
                        @onEvent="get_liquida">
                      </apds_liquidacion_ultimas>

                      <!-- <apds_liquidacion_nueva
                        :padre="padre"
                        @onEvent="get_liquida">
                      </apds_liquidacion_nueva> -->

                      <btraextra
                        :btra_permisos="btra_permisos"
                        :btra_particular="Entorno.btra"
                        btra_modulo="standard"
                        @onEvent="event_btra_Top">              
                      </btraextra> 

                      <v-text-field
                        style="flex: 1 1 40%;padding-left:10px"
                        v-bind="$input"
                        v-model="schema.ctrls.txt.value"
                        :label="schema.ctrls.txt.label"
                        append-icon="mdi-magnify"
                        @keypress.13="filterLiquidaciones"
                        @click:append="filterLiquidaciones">
                      </v-text-field>

                    </div>           
                  </div>
                </template>             
              </base_Fgrid>

              <div class="contenedor">
                <div class="cab">Datos LIQUIDACION</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <div class="conflex" style="padding-top:10px">
                    
                    <vlinput 
                    style="flex: 0 0 26%"
                      label="Fecha "
                      type="date" 
                      v-model="schema.ctrls.fh.value" 
                      disabled> 
                    </vlinput>
            
                    <vllabel
                      style="flex: 0 0 8%"
                      label="Apds"
                      :txt="schema.ctrls.apds.value">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 8%"
                      label="Exped"
                      :txt="schema.ctrls.exped.value">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 22%"
                      label="Total"
                      :txt="schema.ctrls.tot.value|num(2)">
                    </vllabel>                  

                    <vllabel
                      style="flex: 0 0 30%"
                      label="Email"
                      :txt="selected.length && selected[0].fra_emision!= ''? ($options.filters['fechahr'](selected[0].fra_emision)) : ''">
                    </vllabel>
                  </div>                      
                </v-sheet>
              </div>
            </div>
          <!-- </div> -->
        </v-sheet> 
      </div>

      
      <!-- Componentes dinámicos -->      
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado" v-if="selected.length">
            <component                 
              :is="componente_dinamico"
              :padre="stName"
              accion="0"
              :accionRow="record"
              :auxRecord="selected.length? selected[0] : {}"
              :filtro="filtro"
              componenteTipo="FC"
              :disparo="disparo"
              @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
            </component>           
          </div>
      </v-dialog>

  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const apds_liquidacion_ultimas = () => plugs.groute("apds_liquidacion_ultimas.vue", "comp");  
  // const apds_liquidacion_nueva = () => plugs.groute("apds_liquidacion_nueva.vue", "comp");  
    
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btraextra, apds_liquidacion_ultimas },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: ''},
      accionRow: { type:Object, default: null},
      disparoFinder: { type:Boolean, default: false},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            fh: { f:'fh', label:'Fecha', value:""},
            apds: { f:'apds', label:'Apds', value:""},
            exped: { f:'exped', label:'Exped.', value:""},
            tot: { f:'tot', label:'Importe', value:""},            
            asi: { f:'asi', label:'Asiento', value:""},
            txt: { f:'txt', label:'Búsqueda (Código, APD, Cif, Factura)', value:""},
          }
        },

        api:'',
        stName:'stFapds_liquidacion',
        headers: {},
        btra_permisos:{ extra:{}},
        liq:'',
        selected:[],

        dialog:false,
        itemsCC: [],
        itemsTipos: [],

        record:{},
        filtro:'',
        componente_dinamico:null,
        modal:false,
        disparo:false,

      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Liquidaciones APDS"; 
        this.Entorno.grid.autoload= true;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "informe", icono: "mdi-file-chart", title:'Listado Liquidacion' },
          //{ accion: "liquidar", icono: "mdi-apha-l-circle-outline", title:'Liquidacion' },

          { accion: "eliminar", icono: "mdi-delete", title:'Eliminar Liquidación' },          
          { accion: "remesa", icono: "mdi-alpha-r-circle-outline", title:'Generar Remesa y envio Email' },                    
          { accion: "conta", icono: "mdi-alpha-c-circle", title:'Contabilizar Liquidación' },          
          { accion: "emailAll", icono: "mdi-email-multiple-outline", title:'Envio de email a todas las cuentas' },

          
          { accion: "pdf_factura", btn: "btn_pdf", title:'Imprime Factura PDF' },  
          { accion: "email", icono: "mdi-email-outline", title:'Envio de email a la cuenta seleccionada' }
          
        ]; 

        this.headers = {
          header: [
            { text: "Código", value: "apd_cod", witdh: "10%" },
            { text: "APD", value: "apd_name", witdh: "40%" },
            { text: "Cif", value: "cif", witdh: "10%" },
            { text: "Factura", value: "fra_doc", witdh: "10%" },
            { text: "Exped", value: "exped", witdh: "5%" },
            { text: "Importe", value: "tot", witdh: "15%", filtro:'num'  },
            { text: "Asiento", value: "fra_conta_asi", witdh: "10%" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      
        // items adicionales
        // array de cuentas bancarias
        this.itemsCC= [];

        // array de tipos de operaciones
        this.itemsTipos= []
      },


      // obtengo los registros a mostrar
      async ini_component(liq= 0) {
        // limpio selected
        this.selected= [];

        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'get',
          liq: liq
        }};

        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
        if (apiResult.r[0].length) this.selected= [apiResult.r[0][0]];
        
        // calculo totales
        this.calcular_totales();
      },

      // configuración botonera
      botoneras_set() {        
        //console.log('bt: ', this.schema.ctrls.asi.value==0 && !this.selected.length);
        this.btnSet("conta",{ disabled: this.schema.ctrls.asi.value!=0 });
        this.btnSet("eliminar",{ disabled: this.schema.ctrls.asi.value!=0 });        
        this.btnSet("pdf_factura",{ disabled: !this.selected.length });
        this.btnSet("email",{ disabled: !this.selected.length });
      },


      // 
      calcular_totales() {
        // configuro botonera
        this.botoneras_set();

        // calculo totales
        this.schema.ctrls.exped.value= 0, this.schema.ctrls.apds.value= 0, this.schema.ctrls.tot.value= 0;
        
        this.miSt.records.map(elem => {          
          this.schema.ctrls.exped.value= Number(this.schema.ctrls.exped.value) + Number(elem.exped);
          this.schema.ctrls.apds.value= Number(this.schema.ctrls.apds.value) + 1;
          this.schema.ctrls.tot.value= Number(this.schema.ctrls.tot.value) + Number(elem.tot);
          
        });

        if (!this.miSt.records.length) return;        
        this.schema.ctrls.fh.value= this.miSt.records[0].liq_fh;
        this.schema.ctrls.asi.value= this.miSt.records[0].fra_conta_asi;
        this.liq= this.miSt.records[0].liq;
        this.$set(this.Entorno.header, 'titulo', 'APDS LIQUIDACIÓN ' + this.liq);
      },      

    
      // guardo la liquidacion recibida y la cargo
      get_liquida(evt) {
        this.liq= evt.liq;
        this.ini_component(evt.liq);
      },


      // ventana listado últimas liquidaciones
      lista_liquida() {
        this.modal= true;
        this.disparo= !this.disparo;
      },

      /////////// botones


      // listado liquidacion seleccionada
      async informe() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones', fnt:'listado',
          liq: this.liq,
          env: 1
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });      
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
      },

      // eliminar / cancelar liquidacion
      eliminar() {
        this.$root.$alert.open('¿Desea ELIMINAR la Liquidacion ' + this.liq + ' ?', 'confirm')
        .then(r=> { if (r) this.eliminar_continue(); });
      },

      async eliminar_continue() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'eliminar',
          liq: this.liq
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }      
        // recargo liquidacion
        this.ini_component(this.$store.state[this.stName].records.length==1? 0 : this.liq);

        //this.$emit('reloadCuentas');
      },

      // Factura en PDF
      async pdf_factura() {
        let args = { tipo: 'fnc', accion: 'apds', fn_args: { accion: "factura", id: this.selected[0].id, opc: 1 }};        
        console.log('args: ', args); 
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        // abro documento
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
      },

      // envio de email a la cuenta seleccionada
      email() {
        console.log(this.selected);
        if (!this.selected.length) {
          this.$root.$alert.open('Debe seleccionar una cuenta', 'info');
          return;
        }
        this.$root.$alert.open('Enviamos eMail Liquidacion ?<br>APD: <b>'
                               + this.selected[0].apd_name + '</b><br>Factura: <b>' + this.selected[0].fra_doc
                               + '</b><br>Expedientes: <b>' + this.selected[0].exped
                               + '</b><br>Importe: <b>' + this.selected[0].tot+ '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.envio_email(); });
      },

      async envio_email() {
        //igual que pdf_factura
        let args = { tipo: 'fnc', accion: 'apds', fn_args: { accion: "factura", id: this.selected[0].id, ema: '*', opc: 1 }};         
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }      
        this.$root.$alert.open('Email enviado correctamente', 'success');
      },


      // envio de email a todas las cuentas
      async emailAll() {
        this.$root.$alert.open('Enviamos eMail de todas las Facturas ?<br>Liquidacion: <b>' + this.liq + '</b>', 'confirm')
        .then(r=> { if (r) this.envio_emailAll(); });
      },

      async envio_emailAll() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'email',
          liq: this.liq
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        this.$root.$alert.open('Email enviados correctamente', 'success');
      },


      //
      remesa() {
        this.$root.$alert.open('Generamos/Enviamos Liquidación <b>' + this.liq + '</b> ?', 'confirm')
        .then(r=> { if (r) this.remesa_continue(); });
      },

      async remesa_continue() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones', fnt:'remesa',
          liq: this.liq
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        this.$root.$alert.open('Remesa Generada y enviada ...', 'success');
      },


      //  
      conta() {
        this.$root.$alert
        .open("¿CONTABILIZAMOS / LIQUIDACION " + this.liq + "?", "confirm")
        .then(r => { if (r) this.conta_continue(); });
      },

      async conta_continue() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'conta',
          liq: this.liq
        }};
    
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Se ha contabilizado correctamente. Asiento nº ' + apiResult.r[0], 'success');

        // recargo liquidacion
        this.ini_component(this.liq);
      },

      //modales

      cerrar_dinamico() {        
        this.modal= false;
        this.componente_dinamico= null;
      },

       //
      cerrar() {
        //this.$emit('aviso', {accion:null});
        this.cerrar_dinamico();
      },


      //
      event_Header() {
        this.$router.push({ path:'/' });
      },

      
      // 
      filterLiquidaciones() {      
        let txt= this.schema.ctrls.txt.value;
              
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
        
          if (txt!= '') {          
            return (
              elem.apd_cod.toLowerCase().includes(txt.toLowerCase()) || 
              elem.apd_name.toLowerCase().includes(txt.toLowerCase()) || 
              elem.cif.toLowerCase().includes(txt.toLowerCase()) ||           
              elem.fra_doc.toLowerCase().includes(txt.toLowerCase())
            )
          }

          return elem;
        })})
        
        // limpio selección
        this.selected= [];

        // calculo totales
        this.calcular_totales();        
      },
      

    },

    watch: {
      selected() {
        this.botoneras_set();
      }
    }

  };
</script>
